/* Modal.module.css */

.modalOverlay {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background: #fff;
    border-radius: 8px;
    padding: 35px 20px;
    position: relative;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
}

.closeButton svg{
    transition: all ease-in-out 0.5s;
    transform-origin: center;
}

.closeButton {
    position: absolute;
    top: 5px;
    right: 10px;
    width: fit-content;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
    color: #333;
    padding: 0;
    outline: none;
}

.closeButton:hover svg {
    transform: rotateZ(180deg);
    color: #21b72d; /* Змініть колір при наведенні за потребою */
}
