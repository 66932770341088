

.formSection{
    margin: 20px 0;
    display: flex;
}

.imgBackground{
    border: 2px solid #b6f1c7;
    border-radius: 0 150px 150px 0;
    height: 500px;
    width: 45%;
    min-width: 300px;
    padding-top: 30px;
    display: flex;
    align-items: center;
}

.imgBackground img{

    width: 100%;
    margin-left: 0px;
}

.formBlock{
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.formTitle{
    background-color: #b6f1c7;
    border-radius: 100px 0 0 100px;
    height: 100px;
    width: 90%;
    min-width: 300px;
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form{
    width: 90%;
    padding: 30px 0;
    display:flex;
    column-gap: 50px;
    row-gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}

.formInputBlock{
    display:flex;
    flex-direction: column;
    width: 300px;
    justify-content: flex-start;
}

.formInput{
    width: 100%;
    outline: none;
    height: 40px;
    border: none;
    border-bottom: 1px solid #b6f1c7;
    font-size: 20px;
}


.note {
    width: 500px;
    margin: 50px auto;
    font-size: 1.1em;
    color: #333;
    text-align: justify;
}

.dropArea {
    border: 2px dashed #ccc;
    border-radius: 20px;
    width: 70%;
    margin: 10px auto;
    padding: 20px;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}
.uploadForm {
    margin-top: 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadForm input{
    width: 50px;
    height: 50px;
}

.gallery {
    margin-top: 10px;
    display: flex;
}
.gallery img {
    width: 80px;
    height: 70px;
    margin-bottom: 10px;
    margin-right: 10px;
    vertical-align: middle;
}
.button {
    margin: auto;
    display: inline-block;
    padding: 10px;
    background: #b6f1c7;
    cursor: pointer;
    border-radius: 5px;

}
.button:hover {
    background: #90c59f;
}
.fileElem {
    margin: auto;
    display: none;
}

.submitButton{
    width: 50%;
    margin: auto;
    display: inline-block;
    padding: 15px;
    background: #b6f1c7;
    cursor: pointer;
    border-radius: 25px;
    text-align: center;
    color: black;
}

.submitButton:hover{
    background: #90c59f;
    color: black;
}

.submitButton.inactiveButton{
    background-color: #fdfdc0;
}

@media screen and (max-width: 767px) {

    .formSection {
        flex-direction: column;
        row-gap: 20px;
    }
    .imgBackground{
        width: 80%;
        height: fit-content;
    }

    .imgBackground img{
        margin-left: 10%;
        width: 70%;
    }
    .formBlock{
        width: 100%;
        align-items: center;
        position: relative;
    }
    .formTitle{
        position: absolute;
        right: 0;
    }

    .form{
        margin-top: 120px;
    }


}