@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);


.testimonial{
    margin: auto;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 50px;
}

@media screen and (max-width: 500px) {
    .testimonial{
        margin: auto;
        width:70%;
        margin-bottom: 50px;
        max-width: 1200px;
    }
}

.testimonialTitle{
    margin-top: 60px;
    font-weight: 700;
    color: #055e05;
    text-align: center;
    margin-bottom: 30px;
}

figure.card {
    font-family: 'Raleway', Arial, sans-serif;
    position: relative;
    overflow: hidden;
    margin: 10px;
    background-color: transparent;
    min-width: 220px;
    max-width: 310px;
    width: 100%;
    color: #333;
    text-align: left;
    box-shadow: none !important;
}
figure.card * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
figure.card .circle {
    height: 60px;
    width: 115px;
    border-radius: 50%;
    margin-right: 5px;
    display: block;
    z-index: 1;
    position: absolute;
    top: 10px;
    left: 0px;
}
figure.card .circle img {
    height: 60px;
    width: 60px;

}



.red{
    background-color: #ec7474;
}

.yellow{
    background-color: #ecca74;
}

.green{
    background-color: #81d287;
}


figure.card blockquote {
    margin: 0;
    display: block;
    border-radius: 8px;
    position: relative;
    background-color: #fafafa;
    padding: 65px 50px 35px 50px;
    font-size: 0.8em;
    font-weight: 500;
    margin: -40px 0 0;
    line-height: 1.6em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
figure.card blockquote:before,
figure.card blockquote:after {
    font-family: 'FontAwesome';
    content: "\201C";
    position: absolute;
    font-size: 50px;
    opacity: 0.3;
    font-style: normal;
}
figure.card blockquote:before {
    top: 55px;
    left: 20px;
}
figure.card blockquote:after {
    content: "\201D";
    right: 20px;
    bottom: 5px;
}
figure.card .author {
    margin: 0;
    height: 80px;
    display: block;
    text-align: left;
    padding: 0 35px;
    position: relative;
    z-index: 1;
}
figure.card .author h5,
figure.card .author span {
    left: 50%;
    position: absolute;
    opacity: 0.8;
    padding: 3px 5px;
}
figure.card .author h5 {
    text-transform: uppercase;
    bottom: 0;
    margin: 0;
    font-weight: 800;
    color: #070707;
}

.reviewFormBlock{
    margin: auto;
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
    padding: 20px;
    border: 1px solid #9ad5ac;
    background-color: #bbe3c7;
    border-radius:15px;
    margin-bottom: 30px;
}

.reviewFormTitle{
    color: #ffffff;
    font-size: 3em;
    text-align: center;
}

.reviewForm{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.formInputBlock{
    display:flex;
    row-gap: 10px;
    flex-direction: column;
    width: 260px;
    justify-content: flex-start;
}

.formInput{
    width: calc(100% - 20px);
    outline: none;
    height: 40px;
    border: none;
    border-radius: 15px;
    padding: 0 10px;
    font-size: 20px;
    background-color: #ebfcf0;
}

.formInputTextArea{
    width: calc(100% - 20px);
    outline: none;
    border: none;
    height: 80px;
    background-color: #ebfcf0;
    border-radius: 15px;
    font-size: 20px;
    padding: 10px;
}


.btn{
    text-align: center;
    background-color: #4a4a4b;
    color: #fff;
    border-radius: 10px;
    transition: all ease-in-out 1s;
}

.btn:hover{
    color: #fff;
    background-color: #3a3a3a;
}

.formInputBlock span {
    color: #FFFFFF;
    font-weight: 500;
}