
.phone__modal{
    position: fixed;
    bottom: 40px;
    right:30px;
    z-index: 1500;
}
.phone__modal__wrapper{
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 15px;

}
.phone__modal__number{
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.5px;
    width: 0;
    transition: all ease .5s;
    overflow: hidden;
    display: flex;
    align-items: center;
    column-gap: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    margin-right: 67px;

}
.phone__modal__number.show{
    width: 230px;
    padding: 3px 4px;
}

@keyframes pulse {
    0% { box-shadow: 0 0 0 0 rgb(245, 245, 94, 0.4); }
    75%, 100% { box-shadow: 0 0 0 2rem rgb(245, 245, 94, 0); }
}

.phone__modal__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2000;
    border-radius: 50%;
    background-color: #f5f55e;
    box-shadow:
            0 0 0 -2px rgb(245, 245, 94),
            0 2px 4px 0 rgb(245, 245, 94);
    transition: box-shadow 250ms ease;
}

.phone__modal__icon:hover {
    box-shadow:
            0 16px 10px -8px rgba(0,0,0, 0.3),
            0 4px 10px 0 rgba(0,0,0, 0.2);
}

.phone__modal__icon::before,
.phone__modal__icon::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 50%;
    animation: pulse 2s ease-in-out infinite;
}

.phone__modal__icon::after {
    animation-delay: 0.5s;
}




.phone__modal__close__icon{
    fill:#000000;
    width: 40px;
    height: 40px;
}
@media screen and (min-width: 2000px){
    .phone__modal__icon{
        width: 150px;
        height: 150px;
    }
    .phone__modal__number.show{
        width: 600px;
        padding: 10px 4px;
    }
    .phone__modal__close__icon{

        width: 80px;
        height: 80px;
    }
}

.phone__modal__wrapper.mobile{
    display: none;
}

@media screen and (max-width:768px) {
    .phone__modal__wrapper{
        display: none;
    }
    .phone__modal__wrapper.mobile{
        display: block;
    }

    .phone__modal__icon{
        box-shadow:
                0 16px 10px -8px rgba(0,0,0, 0.3),
                0 4px 10px 0 rgba(0,0,0, 0.2);
    }
}

.phoneLink{
    text-decoration: none;
    color: #000000;
}