.aboutUsContainer {
    margin: 20px auto;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-around;
    column-gap: 2px;
    flex-wrap: wrap;
}

.aboutUsContainer .card {
    position: relative;
    cursor: pointer;
}

.aboutUsContainer .card .face {
    width: 290px;
    height: 200px;
    transition: 0.5s;
}

.aboutUsContainer .card .face.face1 {
    position: relative;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(100px);
}

.aboutUsContainer .card:hover .face.face1 {
    background: #92E3A9;
    transform: translateY(0);
}

.aboutUsContainer .card .face.face1 .content {
    opacity: 0.2;
    transition: 0.5s;
}

.aboutUsContainer .card:hover .face.face1 .content {
    opacity: 1;
}

.aboutUsContainer .card .face.face1 .content img {
    max-width: 100px;
}

.aboutUsContainer .card .face.face1 .content h3 {
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
    text-align: center;
    font-size: 1.5em;
}

.aboutUsContainer .card .face.face2 {
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    transform: translateY(-100px);
}

.aboutUsContainer .card:hover .face.face2 {
    transform: translateY(0);
}

.aboutUsContainer .card .face.face2 .content p {
    margin: 0;
    padding: 0;
}

.aboutUsContainer .card .face.face2 .content a {
    margin: 15px 0 0;
    display: inline-block;
    text-decoration: none;
    font-weight: 900;
    color: #333;
    padding: 5px;
    border: 1px solid #333;
}

.aboutUsContainer .card .face.face2 .content a:hover {
    background: #333;
    color: #fff;
}

.whyWeTitle{
    margin-top: 60px;
    font-weight: 700;
    color: #055e05;
    text-align: center;
}

.faceContent{
    display: flex;
}